import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { IconDashboard, IconGraphAsc, IconMinus, IconPlus, IconSettings } from '@Assets';
import { ClientConfiguration, Permission } from '@Models';
import { RootState } from '@Store';
import Sidebar from './sidebar';

interface SidebarContainerProps {
  permissions: string[];
  configuration?: ClientConfiguration;
}

const SidebarContainer = ({ permissions, configuration }: SidebarContainerProps) => {
  const { logout } = useAuth0();
  const { dataRepository, externalPageLinks, resources } = configuration || {};

  // TODO this should be obtained from properties in the page
  const MENU_ITEMS = [
    {
      permission: Permission.NavRecommendation,
      label: 'Main',
      icon: <IconDashboard />,
      link: '/recommendation',
    },
    { permission: Permission.NavMonitor, label: 'Monitor', icon: <IconGraphAsc />, link: '/monitor' },
    {
      permission: Permission.NavActPurchase,
      label: 'Understock',
      icon: <IconMinus />,
      link: '/understock',
      externalLink: externalPageLinks?.understock,
    },
    {
      permission: Permission.NavActSell,
      label: 'Overstock',
      icon: <IconPlus />,
      link: '/overstock',
      externalLink: externalPageLinks?.overstock,
    },
    { permission: Permission.NavConfiguration, label: 'Configure', icon: <IconSettings />, link: '/configure' },
  ];

  const ALWAYS_PRESENT = [Permission.NavMonitor];

  const menuItems = MENU_ITEMS.map((item) => {
    const { permission } = item;
    const enabled = permissions.includes(permission) || ALWAYS_PRESENT.includes(permission);

    if (permission === Permission.NavRecommendation && !enabled) {
      return null;
    }

    return { ...item, enabled };
  }).flatMap((obj) => (obj ? [obj] : []));

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Sidebar onLogout={onLogout} menuItems={menuItems} showResources={!!resources} dataRepository={dataRepository} />
  );
};

const mapStateToProps = (state: RootState) => {
  const { authorization, client } = state;
  const { permissions } = authorization;
  return { permissions, configuration: client?.configuration };
};

export default connect(mapStateToProps)(SidebarContainer);
