import { useNavigate, useParams } from 'react-router-dom';

import Button from '../Button/button';
import { ButtonContainer, Container, InnerContainer, Title } from './notFound.styles';

const NotFound = () => {
  const params = useParams();
  const wrongURL = params['*'];
  const navigate = useNavigate();

  const message = "Page not found.\nWe can't seem to find the page you're looking for.";
  return (
    <Container>
      <InnerContainer>
        <Title>{message}</Title>
        <ButtonContainer>
          <Button
            buttonName="GO_HOME"
            onClick={() => {
              navigate('/');
            }}
            extraLogData={{ wrongURL }}>
            Go Home
          </Button>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
};

export default NotFound;
