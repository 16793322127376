import { useEffect } from 'react';
import {
  Row as ReactTableRow,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ColumnPicker from '../ColumnPicker/columnPicker';
import IndeterminateCheckbox from '../IndeterminateCheckbox/indeterminateCheckbox';
import Select, { SelectOptions } from '../Select/select';
import {
  BodyRow,
  Button,
  ColumnPickerContainer,
  Container,
  EndEllipsis,
  Pagination,
  PaginationIcon,
  Sorted,
  StartEllipsis,
  TableBase,
  TableCard,
  TableContainer,
  TableTools,
  TBody,
  TD,
  TFoot,
  TH,
  THead,
  TR,
} from './table.styles';

export interface Column {
  Header?: string;
  accessor: string;
  Cell?: ({ row }) => void;
}

export interface Row {
  values: object;
  depth: number;
  canExpand?: boolean;
  isExpanded?: boolean;
}

export interface TableFilter {
  column: string;
  value?: any;
}

interface TableProps {
  columns: Column[];
  data: object[];
  pagination?: boolean;
  sortable?: boolean;
  footer?: boolean;
  columnPicker?: boolean;
  selectable?: boolean;
  filters?: TableFilter[];
  getFilteredRows?(rows: ReactTableRow<object>[]);
}

const Table = (props: TableProps) => {
  const { columns, data, pagination, sortable, footer, columnPicker, selectable, filters, getFilteredRows } = props;
  const {
    footerGroups,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((visibleColumn) => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...visibleColumn,
        ]);
      }
    },
  );

  if (getFilteredRows) {
    getFilteredRows(rows);
  }

  useEffect(() => {
    filters?.forEach((filter) => {
      setFilter(filter.column, filter.value);
    });
  }, [filters]);

  const getOptions = () => {
    const options: SelectOptions[] = [15, 20, 50, 100].map((o) => {
      return { value: o.toString(), label: o.toString() };
    });
    return options;
  };
  const totalPagesToDisplay = 6;

  return (
    <Container>
      <TableContainer>
        {columnPicker && (
          <ColumnPickerContainer>
            <ColumnPicker allColumns={allColumns} getToggleHideAllColumnsProps={getToggleHideAllColumnsProps} />
          </ColumnPickerContainer>
        )}
        <TableCard>
          <TableBase {...getTableProps()}>
            <THead>
              {headerGroups.map((headerGroup) => (
                <TR {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const sortByToggleProps = sortable ? column.getSortByToggleProps() : {};
                    return (
                      <TH {...column.getHeaderProps(sortByToggleProps)}>
                        {column.render('Header')}
                        {column.isSorted && <Sorted>{column.isSortedDesc ? '  ↓' : '  ↑'}</Sorted>}
                      </TH>
                    );
                  })}
                </TR>
              ))}
            </THead>
            <TBody {...getTableBodyProps()}>
              {pagination
                ? page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <BodyRow {...row.getRowProps()} depth={row.depth}>
                        {row.cells.map((cell) => (
                          <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                        ))}
                      </BodyRow>
                    );
                  })
                : rows.map((row) => {
                    prepareRow(row);
                    return (
                      <BodyRow {...row.getRowProps()} depth={row.depth}>
                        {row.cells.map((cell) => (
                          <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                        ))}
                      </BodyRow>
                    );
                  })}
            </TBody>

            {footer && (
              <TFoot>
                {footerGroups.map((group) => (
                  <TR {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <TD {...column.getFooterProps()}>{column.render('Footer')}</TD>
                    ))}
                  </TR>
                ))}
              </TFoot>
            )}
          </TableBase>
        </TableCard>
        <TableTools>
          {pagination && (
            <Pagination>
              <Button onClick={() => gotoPage(0)}>
                <PaginationIcon active={pageIndex !== 0}>
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </PaginationIcon>
              </Button>
              <Button onClick={() => previousPage()}>
                <PaginationIcon active={pageIndex !== 0}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </PaginationIcon>
              </Button>
              {pageOptions.length <= totalPagesToDisplay ? (
                pageOptions.map((i) => (
                  <Button onClick={() => gotoPage(i)} active={pageIndex === i} key={i}>
                    {i + 1}
                  </Button>
                ))
              ) : (
                <>
                  {pageIndex > totalPagesToDisplay / 2 && <StartEllipsis>...</StartEllipsis>}
                  {pageOptions.map((i) => {
                    const halfPagesToDisplay = totalPagesToDisplay / 2;
                    const lastPage = pageOptions.length - 1;
                    let minNumber = 0;
                    let maxNumber = lastPage;

                    if (pageIndex <= halfPagesToDisplay) {
                      maxNumber = totalPagesToDisplay;
                    } else if (halfPagesToDisplay <= pageIndex && pageIndex <= lastPage - halfPagesToDisplay) {
                      minNumber = pageIndex - halfPagesToDisplay;
                      maxNumber = pageIndex + halfPagesToDisplay;
                    } else {
                      minNumber = lastPage - totalPagesToDisplay;
                    }
                    if (minNumber <= i && i <= maxNumber) {
                      return (
                        <Button onClick={() => gotoPage(i)} active={pageIndex === i} key={i}>
                          {i + 1}
                        </Button>
                      );
                    }
                    return null;
                  })}
                  {pageIndex < pageOptions.length - totalPagesToDisplay / 2 - 1 && <EndEllipsis>...</EndEllipsis>}
                </>
              )}

              <Button onClick={() => nextPage()}>
                <PaginationIcon active={pageIndex !== pageCount - 1}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </PaginationIcon>
              </Button>
              <Button onClick={() => gotoPage(pageCount - 1)}>
                <PaginationIcon active={pageIndex !== pageCount - 1}>
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </PaginationIcon>
              </Button>
            </Pagination>
          )}
          {pagination && (
            <Select
              defaultValue={getOptions()[0]}
              options={getOptions()}
              onChange={(e: SelectOptions) => {
                setPageSize(Number(e.value));
              }}
            />
          )}
        </TableTools>
      </TableContainer>
    </Container>
  );
};

Table.defaultProps = {
  pagination: false,
  sortable: true,
  footer: false,
  columnPicker: false,
  selectable: false,
  filters: [],
  getFilteredRows: undefined,
};

export default Table;
export type { ReactTableRow };
