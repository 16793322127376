import { useState } from 'react';

import { sendCustomEvent } from '@Analytics';
import Loading from '../Loading/loading';
import {
  Container,
  FrameContainer,
  InnerContainer,
  StyledIconClose,
  StyledIFrame,
  StyledImage,
  Title,
} from './modalVideoCard.styles';

interface ModalVideoCardProps {
  title: string;
  videoId: string;
}

const ModalVideo = ({ title, videoId }: ModalVideoCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    sendCustomEvent('RESOURCE_VIDEO_OPENED', { title });
    setIsOpen(true);
    setLoading(true);
  };
  const handleClose = () => setIsOpen(false);
  const hideSpinner = () => setLoading(false);

  return (
    <>
      <Container>
        <StyledImage
          alt={title}
          src={`https://cdn.loom.com/sessions/thumbnails/${videoId}-with-play.gif`}
          onClick={handleOpen}
        />
        <Title>{title}</Title>
      </Container>
      {isOpen && (
        <InnerContainer>
          <FrameContainer>
            <StyledIconClose onClick={handleClose} />
            {loading && <Loading height={150} width={150} color="#fff" />}
            <StyledIFrame
              title="some title"
              src={`https://www.loom.com/embed/${videoId}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
              frameBorder="0"
              allowFullScreen
              onLoad={hideSpinner}
            />
          </FrameContainer>
        </InnerContainer>
      )}
    </>
  );
};

export default ModalVideo;
export type { ModalVideoCardProps };
