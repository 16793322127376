import { connect } from 'react-redux';

import { ErrorMessage, Loading, withValidPermission } from '@Components';
import { Claim, PerformanceScore, Permission, Recommendation } from '@Models';
import { RootState, useGetPerformanceScoreQuery, useGetRecommendationsQuery } from '@Store';
import RecommendationComponent from './recommendation';

interface RecommendationContainerProps {
  userName: string;
}

const RecommendationContainer = ({ userName }: RecommendationContainerProps) => {
  const {
    data: recommendations,
    error: errorRecommendations,
    isLoading: isLoadingRecommendations,
    isFetching: isFetchingRecommendations,
  } = useGetRecommendationsQuery();

  const {
    data: performance,
    error: errorPerformance,
    isLoading: isLoadingPerformance,
    isFetching: isFetchingPerformance,
  } = useGetPerformanceScoreQuery();

  const getTop5Recommendations = (allRecommendations: Recommendation[]): Recommendation[] => {
    const top20Recommendations = [...allRecommendations]
      .sort((a, b) => b.opportunityValue - a.opportunityValue)
      .slice(0, 20);

    // Shuffle the array using Fisher-Yates algorithm
    for (let i = top20Recommendations.length - 1; i > 0; i = -1) {
      const j = Math.floor(Math.random() * (i + 1));
      [top20Recommendations[i], top20Recommendations[j]] = [top20Recommendations[j], top20Recommendations[i]];
    }
    return top20Recommendations.slice(0, 5);
  };

  if (isLoadingRecommendations || isFetchingRecommendations || isLoadingPerformance || isFetchingPerformance)
    return <Loading title="Hang on we're gathering your recommendations..." />;
  if (errorRecommendations || errorPerformance || !recommendations || !performance) return <ErrorMessage />;

  const roundTo2Decimals = (num: number): string => `${Math.round((num + Number.EPSILON) * 100) / 100}`;

  const getOrderedRecommendations = (): [string, number][] => {
    const countedRecommendations: Record<string, number> = {};

    recommendations.forEach((recommendation) => {
      if (!countedRecommendations[recommendation.type]) countedRecommendations[recommendation.type] = 0;

      countedRecommendations[recommendation.type] += 1;
    });

    const knownOrder: Record<string, number> = {
      'Important Overstock': 0,
      Overstock: 1,
      'Potential Overstock': 2,
      'Potential Understock': 3,
      Understock: 4,
      'Potential Stockout': 5,
      'Store Stockout': 6,
      'Complete Stockout': 7,
    };

    const sortedCountedRecommendations: [string, number][] = Object.entries(countedRecommendations).sort((a, b) => {
      const aOrder = knownOrder[a[0]] !== undefined ? knownOrder[a[0]] : 999;
      const bOrder = knownOrder[b[0]] !== undefined ? knownOrder[b[0]] : 999;

      return aOrder - bOrder;
    });

    return sortedCountedRecommendations;
  };

  const orderedRecommendations = getOrderedRecommendations();
  const recommendationsChart = {
    labels: orderedRecommendations.map((recommendation) => recommendation[0]),
    values: orderedRecommendations.map((recommendation) => recommendation[1]),
    colors: ['#DE425B', '#F8A6A9', '#F8A6A9', '#DE425B', '#DE425B', '#FF0000'],
  };

  const top5Recommendations = getTop5Recommendations(recommendations);
  const recommendationsId = top5Recommendations.map((recommendation) => recommendation.id);
  const lastPerformance: PerformanceScore = performance[performance.length - 1];
  const statusIndicators = [
    {
      title: 'Inventory Efficiency Score',
      value: `${roundTo2Decimals(lastPerformance.efficiencyScore * 100)}%`,
    },
    {
      title: 'Inventory Days',
      value: roundTo2Decimals(lastPerformance.inventoryDays),
    },
  ];

  return (
    <RecommendationComponent
      recommendations={top5Recommendations}
      statusIndicators={statusIndicators}
      recommendationsChart={recommendationsChart}
      recommendationsId={recommendationsId}
      userName={userName}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { authorization } = state;
  const { claims }: { claims: Record<Claim, string> } = authorization;

  const userName = claims[Claim.UserDisplayName];
  return { userName };
};

export default withValidPermission(connect(mapStateToProps)(RecommendationContainer), Permission.NavRecommendation);
