import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { MessageIcon } from '@Components';

const Unauthorized = () => {
  const message =
    "You're not authorized to view this page.\nIf you think this is an error please contact us at support@bops.ai.";
  return <MessageIcon message={message} icon={faTriangleExclamation} iconColor="#AAAA00" iconSize="3x" />;
};

export default Unauthorized;
