import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface MiscellaneousState {
  pipelineRefresh?: number;
}

type MiscellaneousKeys = keyof MiscellaneousState;

export const initialState: MiscellaneousState = {
  pipelineRefresh: undefined,
};

const reducers = {
  updateValue: (state, action: PayloadAction<MiscellaneousState>) => {
    const { payload } = action;

    const keys = Object.entries(payload).filter(([key, value]) => value !== null);
    keys.forEach(([key, value]) => {
      state[key] = value;
    });
  },
  setNullValue: (state, action: PayloadAction<MiscellaneousKeys>) => {
    const { payload } = action;
    if (payload in state) state[payload] = null;
  },
};

const slice = createSlice({ initialState, name: 'miscellaneous', reducers });

export const { actions } = slice;
export default slice.reducer;

export type { MiscellaneousKeys, MiscellaneousState };
