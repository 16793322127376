import ModalVideoCard, { ModalVideoCardProps } from '../ModalVideoCard/modalVideoCard';
import {
  CardsContainer,
  CardsOuterContainer,
  Container,
  FrameContainer,
  InnerContainer,
  StyledIconClose,
  Title,
} from './modalResourceVideos.styles';

interface ModalResourceVideosProps {
  show: boolean;
  handleClose: () => void;
  videos: ModalVideoCardProps[];
}

const ModalResourceVideos = ({ show, handleClose, videos }: ModalResourceVideosProps) => {
  return (
    <Container>
      {show && (
        <InnerContainer>
          <FrameContainer>
            <StyledIconClose onClick={handleClose} />
            <Title>Training videos</Title>
            <CardsOuterContainer>
              <CardsContainer>
                {videos.map((video) => (
                  <ModalVideoCard key={video.title} {...video} />
                ))}
              </CardsContainer>
            </CardsOuterContainer>
          </FrameContainer>
        </InnerContainer>
      )}
    </Container>
  );
};

export default ModalResourceVideos;
