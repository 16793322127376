import { useEffect, useRef } from 'react';
import { Menu, useProSidebar } from 'react-pro-sidebar';
import { matchPath, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useWindowSize } from 'usehooks-ts';
import { faAnglesLeft, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BopsLogoExpanded, BopsTitleLogo, GS1MexLogo2, IconArchive } from '@Assets';
import { screenSize } from '@Themes';
import config from '../../config';
import ModalResourceVideos, { ModalResourceVideosRef } from '../ModalResourceVideos/modalResourceVideos.container';
import {
  Anchor,
  Content,
  Footer,
  Header,
  HeaderLogo,
  LogoutContainer,
  PageLinkContainer,
  Separator,
  SidebarLink,
  SideBarText,
  StyledMenuItem,
  StyledNavMenuItem,
  StyledSidebar,
  StyledSubMenu,
} from './sidebar.styles';

interface MenuItemProps {
  label: string;
  icon: JSX.Element;
  link: string;
  enabled: boolean;
  externalLink?: string;
}

interface SidebarProps {
  menuItems: MenuItemProps[];
  onLogout: () => void;
  showResources: boolean;
  dataRepository?: string;
}

const Sidebar = ({ menuItems, onLogout, showResources, dataRepository }: SidebarProps) => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const { width } = useWindowSize();
  const modalResourceRef = useRef<ModalResourceVideosRef>(null);

  useEffect(() => {
    if (width > 0 && width < screenSize.autoCollapseSideBar) {
      collapseSidebar(true);
    }
  }, [width]);

  const buildTooltip = ({ id, content }: { id: string; content: string }) => {
    return collapsed && <ReactTooltip id={id} positionStrategy="fixed" place="right" content={content} offset={18} />;
  };

  const buildMenuItem = ({ label, icon, link, enabled, externalLink }: MenuItemProps) => {
    const isPathActive = !!matchPath(useLocation().pathname, link);
    const tooltipId = `tooltip-${label}`;

    return (
      <StyledNavMenuItem key={label} $collapsed={collapsed} $isPathActive={isPathActive}>
        {externalLink ? (
          <PageLinkContainer data-tooltip-id={tooltipId}>
            <Anchor href={externalLink} target="_blank" rel="noreferrer">
              {icon}
              <SideBarText $collapsed={collapsed}>{label}</SideBarText>
            </Anchor>
          </PageLinkContainer>
        ) : (
          <StyledMenuItem
            data-tooltip-id={tooltipId}
            icon={icon}
            $isActive={isPathActive}
            $isDisabled={!enabled}
            component={<SidebarLink to={link} />}>
            {!collapsed && label}
          </StyledMenuItem>
        )}
        {buildTooltip({ id: tooltipId, content: label })}
      </StyledNavMenuItem>
    );
  };
  const menuItemStyles = {
    button: () => {
      return {
        '&:hover': {
          backgroundColor: '#454545',
          borderTopRightRadius: '25px',
          borderBottomRightRadius: '25px',
        },
      };
    },
  };

  const getLogo = () => {
    if (config.REACT_APP_FLAVOUR === 'gs1mex')
      return collapsed ? <GS1MexLogo2 height={35} width={61} /> : <GS1MexLogo2 height={50} width={88} />;

    return collapsed ? <BopsLogoExpanded height={35} width={28} /> : <BopsTitleLogo height={35} width={125} />;
  };

  return (
    <StyledSidebar>
      <Header $collapsed={collapsed}>
        <>
          {getLogo()}
          <HeaderLogo onClick={() => collapseSidebar()}>
            <FontAwesomeIcon icon={faAnglesLeft} size="lg" color="white" transform={{ flipX: collapsed }} />
          </HeaderLogo>
        </>
      </Header>
      <Separator />
      <Content>
        <Menu menuItemStyles={menuItemStyles}>{menuItems.map((menuItem) => buildMenuItem(menuItem))}</Menu>
      </Content>
      <Footer>
        <Menu menuItemStyles={menuItemStyles} closeOnClick>
          {buildTooltip({ id: 'tooltip-documentation', content: 'Documentation' })}
          <StyledSubMenu
            icon={<IconArchive />}
            $collapsed={collapsed}
            label="Toolkit"
            data-tooltip-id="tooltip-documentation">
            {dataRepository && (
              <StyledMenuItem component={<SidebarLink to={`/documentation/${dataRepository}`} />}>
                Documentation
              </StyledMenuItem>
            )}

            {showResources && (
              <>
                <ModalResourceVideos ref={modalResourceRef} />
                {buildTooltip({ id: 'tooltip-resources', content: 'Resources' })}
                <StyledMenuItem
                  data-tooltip-id="tooltip-resources"
                  onClick={() => modalResourceRef.current?.showModal()}>
                  Training videos
                </StyledMenuItem>
              </>
            )}

            {buildTooltip({ id: 'tooltip-Insights', content: 'Insights' })}
            <StyledMenuItem href="https://www.bops.ai/resources-hub" target="_blank" rel="noreferrer">
              Insights
            </StyledMenuItem>
          </StyledSubMenu>
        </Menu>

        {buildTooltip({ id: 'tooltip-logout', content: 'Logout' })}
        <LogoutContainer data-tooltip-id="tooltip-logout" onClick={onLogout}>
          <FontAwesomeIcon icon={faSignOut} size="lg" transform={{ flipX: true }} />
          <SideBarText $collapsed={collapsed}>Logout</SideBarText>
        </LogoutContainer>
      </Footer>
      {showResources && <ModalResourceVideos ref={modalResourceRef} />}
    </StyledSidebar>
  );
};

export default Sidebar;
