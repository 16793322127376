enum Permission {
  ConfigGlogalInventoryPolicy = 'config:global_inventory_policy',
  ConfigLeveInventoryPolicy = 'config:level_inventory_policy',
  ConfigLocationMaster = 'config:location_master',
  ConfigLocationProductMaster = 'config:location_product_master',
  ConfigProductMaster = 'config:product_master',
  ConfigSourceDestinationLocationMaster = 'config:source_destination_location_master',
  NavActPurchase = 'nav:actPurchase',
  NavActSell = 'nav:actSell',
  NavConfiguration = 'nav:config',
  NavMonitor = 'nav:monitor',
  NavRecommendation = 'nav:recommendation',
  PipelineRefresh = 'pipeline:refresh',
  OpenAiMonitor = 'openai:monitor',
  DevOpenAiPrompt = 'dev:openai:prompt',
}

/* eslint import/prefer-default-export: "off" */
export { Permission };
