import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { runningOnProd } from '../Initialization';

let awsRum: AwsRum | null = null;

const initializeAwsRum = () => {
  if (!runningOnProd()) return;

  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: 'arn:aws:iam::244227163619:role/RUM-Monitor-us-east-1-244227163619-4622622110861-Unauth',
      identityPoolId: 'us-east-1:e38d00fe-eebd-4156-bb78-ff41acdacdbc',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID = 'fa5c88da-cadd-47fe-bd1c-408aa6bd2705';
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-1';

    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
  } catch (error) {
    // TODO log me
  }
};

const addUserMetadata = ({
  clientName = '',
  email = '',
  userName = '',
}: {
  clientName?: string;
  email?: string;
  userName?: string;
}) => {
  if (!runningOnProd()) return;

  try {
    awsRum?.addSessionAttributes({
      clientName,
      email,
      userName,
    });
  } catch (error) {
    // TODO log me
  }
};

const sendCustomEvent = (eventName: string, eventAttributes: object = {}) => {
  if (!runningOnProd()) {
    console.log('Not runnig in production environment. Skipping event: ', eventName, eventAttributes);
    return;
  }

  try {
    awsRum?.recordEvent(eventName, eventAttributes);
  } catch (error) {
    // TODO log me
  }
};

export default initializeAwsRum;
export { addUserMetadata, sendCustomEvent };
