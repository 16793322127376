import { ErrorMessage, Loading, withValidPermission } from '@Components';
import { useFetchData } from '@CustomHooks';
import { ConfigurationFile, Permission } from '@Models';
import { bopsApi } from '@Network';
import Configure from './configure';

const ConfigureContainer = () => {
  const { data, isLoading, error, refetch } = useFetchData(bopsApi.getFilesConfiguration);

  if (isLoading) return <Loading title="Hang on we're gathering your configuration files..." />;

  if (error || !data) return <ErrorMessage />;

  const fileUpdated = () => {
    refetch();
  };

  return <Configure configurationFiles={data as ConfigurationFile[]} fileUpdated={fileUpdated} />;
};

export default withValidPermission(ConfigureContainer, Permission.NavConfiguration);
