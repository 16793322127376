import {
  RecommendationAiWidget,
  RecommendationCard,
  RecommendationCardProps,
  RecommendationsBarChart,
  StatusIndicator,
  StatusIndicatorProps,
} from '@Components';
import {
  Container,
  ContentTitle,
  InnerContainer,
  LeftContainer,
  RightContainer,
  SpanBold,
  StatusIndicatorContainer,
  Title,
  WelcomeMessage,
} from './recommendation.styles';

interface RecommendationProps {
  statusIndicators: StatusIndicatorProps[];
  recommendations: RecommendationCardProps[];
  recommendationsChart: {
    labels: string[];
    values: number[];
    colors: string[];
  };
  userName?: string;
  recommendationsId: string[];
}

const Recommendation = ({
  recommendations,
  statusIndicators,
  recommendationsChart,
  userName,
  recommendationsId,
}: RecommendationProps) => {
  const salute = () =>
    userName ? (
      <>
        Hi <SpanBold>{userName}</SpanBold>, here
      </>
    ) : (
      <>Here</>
    );

  return (
    <Container>
      <Title>Dashboard</Title>
      <WelcomeMessage>
        {salute()} is a summary of your current score and {recommendations.length} recommendations that can help
        simplify your inventory decision-making process.
      </WelcomeMessage>
      <InnerContainer>
        <LeftContainer>
          <StatusIndicatorContainer>
            {statusIndicators.map((statusIndicator) => (
              <StatusIndicator {...statusIndicator} key={statusIndicator.title} />
            ))}
          </StatusIndicatorContainer>
          <RecommendationsBarChart
            labels={recommendationsChart.labels}
            values={recommendationsChart.values}
            colors={recommendationsChart.colors}
          />
        </LeftContainer>
        <RightContainer>
          <ContentTitle>Top {recommendations.length} Recommendations</ContentTitle>
          {recommendations.map((recommendation) => (
            <RecommendationCard {...recommendation} key={recommendation.locationCode + recommendation.productCode} />
          ))}
        </RightContainer>
      </InnerContainer>
      <RecommendationAiWidget />
    </Container>
  );
};

export default Recommendation;
