import { connect } from 'react-redux';

import { withValidPermission } from '@Components';
import { useFetchData } from '@CustomHooks';
import { ClientConfiguration, Permission } from '@Models';
import { bopsApi } from '@Network';
import { RootState, usePostPipelineRunsMutation } from '@Store';
import Basestock from './basestock';

interface BasestockContainerProps {
  configuration: ClientConfiguration;
  configurationPath: keyof ClientConfiguration;
}

const BasestockContainer = ({ configuration, configurationPath }: BasestockContainerProps) => {
  const {
    data: lastUpdate,
    error: errorLastUpdate,
    isFetching: isFetchingLastUpdate,
  } = useFetchData(bopsApi.getPipelineLastUpdate);

  const [triggerPipeline, { isError: isTriggerError, isLoading: isTriggerLoading }] = usePostPipelineRunsMutation();

  const lastUpdateText = String(
    isFetchingLastUpdate ? 'loading' : errorLastUpdate || !lastUpdate ? 'Not available' : lastUpdate,
  );

  const resetTimer = isTriggerError && !isTriggerLoading;

  const onRefreshClick = () => {
    triggerPipeline();
  };

  const source = configuration[configurationPath] as string;

  return (
    <Basestock
      lastUpdateText={lastUpdateText}
      source={source}
      onRefreshClick={onRefreshClick}
      resetTimer={resetTimer}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client;
  return { configuration };
};

export default (permission: Permission) =>
  withValidPermission(connect(mapStateToProps)(BasestockContainer), permission);
