import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBell, faLayerGroup, faPlusCircle, faStream } from '@fortawesome/free-solid-svg-icons';

import { AuthenticationGuard, ErrorMessage, withSidebar } from '@Components';
import {
  ConfigureLink,
  Documentation,
  Landing,
  Monitor,
  NotFound,
  Overstock,
  Recommendation,
  Unauthorized,
  Understock,
} from '@Pages';
import { GlobalStyle } from '@Themes';
import { AppContainer } from './app.styles';
import './app.scss';

library.add(faBell, faLayerGroup, faPlusCircle, faStream);

const App = () => {
  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <ErrorBoundary fallback={<ErrorMessage />}>
          <Routes>
            <Route element={<AuthenticationGuard component={withSidebar} />}>
              <Route path="recommendation" element={<Recommendation />} />
              <Route path="monitor" element={<Monitor />} />
              <Route path="understock" element={<Understock />} />
              <Route path="overstock" element={<Overstock />} />
              <Route path="configure" element={<ConfigureLink />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="documentation/:pageId" element={<Documentation />} />
              <Route path="documentation" element={<Documentation />} />
              <Route path="landing" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/" element={<Navigate to="/landing" replace />} />
          </Routes>
        </ErrorBoundary>
      </AppContainer>
    </>
  );
};

export default App;
