import axios from 'axios';
import { DateTime } from 'luxon';

import {
  AiRecommendation,
  aiRecommendationFromJson,
  ClientConfiguration,
  ConfigurationFile,
  configurationFileFromJson,
} from '@Models';
import { getAccessToken } from '@Store';
import config from '../config';

const BASEAPI_ADDRESS = config.REACT_APP_BASE_API_ADDRESS;

const bopsApi = ((baseApiAddress: string) => {
  const api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 20000,
  });
  // MIDDLEWARE
  // onRequest
  api.interceptors.request.use(
    async (configuration) => {
      const accessToken = await getAccessToken();

      const updatedConfig = configuration;
      updatedConfig.headers.Authorization = `Bearer ${accessToken}`;

      return updatedConfig;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  /*
  //onResponse
  instance.interceptors.response.use(
    response => {
      // do custom thing for every response
      return response
    },
    error => {
      return Promise.reject(error)
    }
  )
  */

  return {
    requestDownloadConfigurationFile: (fileType: string) => {
      return api.get(`configuration/file/presigned/${fileType}`);
    },
    requestUploadConfigurationFile: (fileType: string) => {
      return api.post(`configuration/file/presigned/${fileType}`);
    },
    getFilesConfiguration: async (): Promise<ConfigurationFile[]> => {
      const response = await api.get('configuration/files');
      const { data } = response;
      return data.map((configurationFile) => configurationFileFromJson(configurationFile));
    },
    getPipelineLastUpdate: async (): Promise<string> => {
      const response = await api.get('pipelineruns/lastUpdate');
      const { data } = response;

      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const datetime = DateTime.fromISO(data).setZone(timeZone);

      return datetime.toLocaleString(DateTime.DATETIME_FULL);
    },
    getQuickSightDashboard: async (): Promise<string> => {
      const response = await api.get(`quicksight/dashboard_embedded`);
      const { data } = response;

      return String(data);
    },
    getQuickSightTopic: async (): Promise<string> => {
      const response = await api.get(`quicksight/qsearchbar_embedded`);
      const { data } = response;

      return String(data);
    },
    getAiRecommendationByProductCode: async (productCode: string): Promise<AiRecommendation> => {
      const response = await api.get(`recommendations/ai?product_codes=${productCode}`);
      const { data } = response;
      let singleRec;

      if (Array.isArray(data)) singleRec = data[0]; // eslint-disable-line prefer-destructuring

      return aiRecommendationFromJson(singleRec || data);
    },
    getDocumentationPage: async (pageId: string): Promise<any> => {
      const response = await api.get(`resources/${pageId}`);
      const { data } = response;
      return data;
    },
    getClientConfiguration: async (): Promise<ClientConfiguration> => {
      const response = await api.get('configuration');
      const { data } = response;
      return data;
    },
  };
})(BASEAPI_ADDRESS);

export default bopsApi;
