import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { Container, InnerContainer, StyledIcon, Title } from './errorMessage.styles';

const ErrorMessage = ({ message }: { message?: string }) => {
  const finalMessage =
    message ||
    "Something has gone wrong, but it's likely to work again soon.\nCheck back in a while, and if it's still not working contact us at support@bops.ai.";

  return (
    <Container>
      <InnerContainer>
        <Title>{finalMessage}</Title>
        <StyledIcon icon={faTriangleExclamation} />
      </InnerContainer>
    </Container>
  );
};

ErrorMessage.defaultProps = {
  message: undefined,
};

export default ErrorMessage;
