import styled from 'styled-components';

import { StyledPrimaryButton } from '../Button/button.styles';

const maxWidthChanged = '950px'; // set this to tablet
const smallWidthChanged = '520px'; // set this to phone

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  border-radius: 0.8rem;
  min-height: 80px;
  width: 100%;
  max-width: 750px;
  margin: 0 auto 0 0;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  background: hsl(220deg 14% 97%);
  background-color: white;
  border: 1px solid #eee;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  color: black;
  padding: 0px 20px;
  box-sizing: border-box;

  ${StyledPrimaryButton} {
    margin: auto 0px;
    min-width: 70px;
  }
`;

export const Title = styled.h4`
  margin: 12px 0px 0px 0px;
  font-size: 20px;
  box-sizing: border-box;
  color: #333;

  @media (max-width: ${maxWidthChanged}) {
    font-size: 16px;
  }

  @media (max-width: ${smallWidthChanged}) {
    font-size: 15px;
    word-wrap: break-word;
    width: 185px;
    vertical-align: middle;
    height: 30px;
    margin: 12px 0px 6px 0px;
    display: flex;
    align-items: center;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.darkGray};
  margin: 6px 0px 12px 0px;

  @media (max-width: ${maxWidthChanged}) {
    font-size: 12px;
  }
`;
