import Button from '../Button/button';
import { Container, Description, Title, TitleContainer } from './fileLinkCard.styles';

interface FileLinkCardProps {
  title: string;
  link: string;
  description?: string;
}

const FileLinkCard = ({ title, link, description }: FileLinkCardProps) => {
  const openFileInNewWindow = () => {
    window.open(link, '_blank');
  };
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </TitleContainer>
      <Button
        onClick={openFileInNewWindow}
        buttonName="CONFIGURATION_LINK_OPENED"
        extraLogData={{ title, link }}
        height="40px"
        fontSize="0.8rem">
        Open
      </Button>
    </Container>
  );
};

export default FileLinkCard;
