import styled, { css } from 'styled-components';

export const BaseButton = css<{ $height: string; $fontSize: string }>`
  border-radius: 10px;
  height: ${({ $height }) => $height};
  font-size: ${({ $fontSize }) => $fontSize};

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.35);
  }
`;

export const StyledPrimaryButton = styled.button<{ $height: string; $fontSize: string }>`
  ${BaseButton}

  border: 2px solid ${(props) => props.theme.colors.bopsPurple};
  background-color: ${(props) => props.theme.colors.bopsPurple};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    border-color: ${(props) => props.theme.colors.bopsPurpleHover};
    background-color: ${(props) => props.theme.colors.bopsPurpleHover};
  }
`;

export const StyledSecondaryButton = styled.button<{ $height: string; $fontSize: string }>`
  ${BaseButton}

  border: 2px solid ${(props) => props.theme.colors.gray};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkGray};

  &:hover {
    background-color: ${(props) => props.theme.colors.gray};
    color: ${(props) => props.theme.colors.white};
  }
`;
