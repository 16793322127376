import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;

  padding: ${(props) => `0px ${props.theme.padding.small}px`};

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    padding: ${(props) => `${props.theme.padding.small}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    padding: ${(props) => `${props.theme.padding.medium}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding: ${(props) => `${props.theme.padding.large}px`};
  }
`;

export const InnerContainer = styled.div`
  margin: auto;
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
  padding: 40px 20px;
`;

export const Title = styled.h1`
  font-weight: 1000;
  line-height: 1;

  display: none;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    display: flex;
  }
`;

export const WelcomeMessage = styled.p`
  color: #6d6969;
  margin-top: 0px;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    margin-top: ${(props) => `${props.theme.padding.small}px`};
  }
`;
