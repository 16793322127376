import { useRef } from 'react';

import { RefreshPipelineButton } from '@Components';
import { MyIFrame } from './basestock.styles';

const Basestock = ({
  source,
  lastUpdateText,
  onRefreshClick,
  resetTimer,
}: {
  source: string;
  lastUpdateText: string;
  onRefreshClick: () => void;
  resetTimer: boolean;
}) => {
  return (
    <>
      <RefreshPipelineButton
        defaultTooltip={`Last update:\n ${lastUpdateText}`}
        onRefreshClick={onRefreshClick}
        resetTimer={resetTimer}
      />
      <MyIFrame src={source} />
    </>
  );
};

export default Basestock;
