const config = {
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CALLBACK_URL: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_BASE_API_ADDRESS: process.env.REACT_APP_BASE_API_ADDRESS || '',
  REACT_APP_OPEN_AI_PROMPT: process.env.REACT_APP_OPEN_AI_PROMPT || '',
  REACT_APP_FLAVOUR: process.env.REACT_APP_FLAVOUR || 'bops',
};

export default config;
