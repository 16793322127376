import styled, { css } from 'styled-components';

import { BopsLogo, IconAi } from '@Assets';

export const Container = styled.div`
  color: ${(props) => props.theme.colors.bopsPurple};
  background-color: ${(props) => props.theme.colors.bopsPurple};

  position: fixed;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: end;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  height: 54px;
  width: 245px;
  bottom: 35px;
  right: 30px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    height: 44px;
    width: 230px;
    bottom: 13px;
    right: 10px;
  }
`;

export const LoadingContainer = styled.div`
  margin: 0px auto;
  padding-left: 18px;
  width: auto;
`;

export const Text = styled.h3`
  color: ${(props) => props.theme.colors.white};
  padding-right: 20px;
  padding-left: 12px;
  cursor: pointer;
  height: 100%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  align-items: center;
  font-weight: 500;

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 16px;
  }
`;

const iconStyle = css`
  width: 24px;
  height: 24px;
  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 24px;
    height: 24px;
  }
`;

export const StyledIconAi = styled(IconAi)`
  ${iconStyle}
`;

export const StyledBopsLogo = styled(BopsLogo)`
  width: 24px;
  height: 24px;
`;

export const ParentContainer = styled.div`
  .MuiPaper-root {
    background-color: ${(props) => props.theme.colors.white} !important;
  }
  .css-16z8tzi {
    background-color: ${(props) => props.theme.colors.white} !important;
  }

  .h4 {
    background-color: ${(props) => props.theme.colors.white} !important;
  }

  .css-1ed8v3f-MuiTypography-root {
    color: ${(props) => props.theme.colors.white} !important;
  }
`;
