import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const TableContainer = styled.div``;

export const TableCard = styled.div`
  border: 1px solid ${(props) => props.theme.colors.cardBorder};
  width: auto;
  height: auto;
  z-index: 1000;
  box-shadow: 0 0 5px ${(props) => props.theme.colors.shadow};
`;

export const TableBase = styled.table`
  border-collapse: collapse;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TFoot = styled.tfoot`
  border-top: solid 2px ${(props) => props.theme.colors.transparentLightGrey2};
`;

export const TR = styled.tr``;

export const BodyRow = styled.tr<{ depth: number }>`
  background: #ffffff;

  &:not(:last-child) {
    border-bottom: solid 1px ${(props) => props.theme.colors.transparentLightGrey};
  }

  &:last-child {
    border-radius: 20px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.transparentLightGrey} !important;
  }
`;

export const TD = styled.td`
  color: ${(props) => props.theme.colors.tableHeader};
  max-width: 20vw;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
  overflow-wrap: break-word;
  cursor: pointer;
  padding: 10px 20px 8px 8px;
  border-bottom: solid 0.5px ${(props) => props.theme.colors.cardBackgroundTransparent};

  & > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover {
      overflow: visible;
      white-space: unset;
    }
  }
`;

export const TH = styled.th`
  background-color: ${(props) => props.theme.colors.cardBackgroundTransparent};
  color: ${(props) => props.theme.colors.tableHeader};
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px 20px 5px 5px;
  border-bottom: solid 1.5px ${(props) => props.theme.colors.tableHeader};

  &:not(:first-child):hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.cardBorderTransparent} !important;
  }
`;

export const Sorted = styled.span``;

export const Button = styled.div<{ active?: boolean }>`
  cursor: pointer;
  display: flex;
  height: 10px;
  width: 25px;
  transition-duration: 0.5s;

  color: ${({ active, theme }) => (active ? theme.colors.bopsPurple : theme.colors.tableHeader)};
  font-weight: ${({ active }: { active?: boolean }) => (active ? '800' : '400')};

  &:hover {
    font-weight: 800;
  }
`;

export const TableTools = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1vh 10px;
  align-items: left;
  justify-content: left;
`;

export const PaginationIcon = styled.div<{ active: boolean }>`
  color: ${({ active, theme }: { active?: boolean; theme: any }) =>
    active ? theme.colors.tableHeader : theme.colors.cardBorder2};

  &:hover {
    color: ${(props) => props.theme.colors.cardBorder2};
    border: 1px solid transparent;
  }
`;

export const ColumnPickerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FilterContainer = styled.div``;

export const StartEllipsis = styled.div`
  margin-right: 10px;
  color: black;
`;
export const EndEllipsis = styled.div`
  margin-right: 10px;
  color: black;
`;
