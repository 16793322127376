import { MouseEvent } from 'react';
import { Invokable, Widget } from '@buildwithlayer/sdk';

import { sendCustomEvent } from '@Analytics';
import { bopsTheme } from '@Themes';
import Loading from '../Loading/loading';
import {
  Container,
  LoadingContainer,
  ParentContainer,
  StyledBopsLogo,
  StyledIconAi,
  Text,
} from './openAiWidget.styles';

interface OpenAiWidgetProps {
  welcomeMessage: string;
  invokables: Invokable[];
  isLoading?: boolean;
}

const OpenAiWidget = ({ invokables, welcomeMessage, isLoading = false }: OpenAiWidgetProps) => {
  const containerOnClick = (
    widgetOnClick: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void,
    event: unknown,
  ) => {
    if (isLoading) return;
    widgetOnClick(event as MouseEvent<HTMLButtonElement, globalThis.MouseEvent>);
    sendCustomEvent('OPEN_AI_WIDGET_OPENED', {});
  };

  return (
    <ParentContainer>
      <Widget
        openAiApiKey={process.env.REACT_APP_OPEN_AI_API_KEY || ''}
        layerApiKey={process.env.REACT_APP_LAYER_API_KEY || ''}
        invokables={invokables}
        copilotName="bops"
        userName="You"
        // Optional title for the header
        title="bops Assistant"
        // Optional function to render a logo in the header
        renderLogo={() => <StyledBopsLogo />}
        // Optional function to render a custom fab
        renderFab={(widgetOnClick) => (
          <Container onClick={(event) => containerOnClick(widgetOnClick, event)}>
            {isLoading ? (
              <LoadingContainer>
                <Loading width={24} height={24} color={bopsTheme.colors.white} />
              </LoadingContainer>
            ) : (
              <StyledIconAi />
            )}
            <Text>{isLoading ? 'Loading assistant' : 'Ask bops assistant'}</Text>
          </Container>
        )}
        // Optional string to override the default message in the chat
        defaultMessage={welcomeMessage}
        copilot="basic"
      />
    </ParentContainer>
  );
};

export default OpenAiWidget;

export type { OpenAiWidgetProps };
