import { DefaultTheme } from 'styled-components';

import colors from './colors';
import { padding, screenSize } from './sizes';

const bopsPurple2 = '#7e6cf6';
const bopsPurple3 = '#AFA4F6';

const bopsTheme: DefaultTheme = {
  breakpoints: {
    mobile: `only screen and (max-width: ${screenSize.mobile}px)`,
    tablet: `only screen and (min-width: ${screenSize.mobile + 1}px)`,
    tabletLandscape: `only screen and (min-width: ${screenSize.tablet}px)`,
    desktopSmall: `only screen and (min-width: ${screenSize.desktopSmall + 1}px)`,
    desktop: `only screen and (min-width: ${screenSize.desktop}px)`,
    desktopLarge: `only screen and (min-width: ${screenSize.desktopLarge}px)`,
    desktopXLarge: `only screen and (min-width: ${screenSize.desktopXLarge}px)`,
  },
  colors: {
    ...colors,
    background: '#f3f3f3',
    bopsPurple: '#7c6cf6',
    bopsPurple2,
    bopsPurple3,
    bopsPurpleActive: '#5f52b1',
    bopsPurpleDark: '#4A4666',
    bopsPurpleHover: '#483F8F',
    bopsPurpleLight: '#968DCD',
    bopsPurpleTransparent: 'rgba(125, 106, 246, 0.1)',
    bopsPurpleTransparent2: 'rgba(125, 106, 246, 0.25)',
    bopsPurpleTransparent3: 'rgba(125, 106, 246, 0.75)',
    cardBackground: '#1d1d22',
    cardBackground2: '#25252C',
    cardBackground3: '#3b3b44',
    cardBackground4: '#515F6C',
    cardBackground5: '#596570',
    cardBackgroundTransparent: '#DFDFDF',
    cardBorder: '#31313a',
    cardBorder2: '#383842',
    cardBorder3: '#4c4c55',
    cardBorderTransparent: '#31313a60',
    errorRed: '#bf1650',
    fontBlack: colors.black,
    fontGray: '#c9d1d9',
    neutral80: '#000000',
    nightBlue: '#011233',
    primary: bopsPurple2,
    primary25: bopsPurple3,
    primary50: '#AAAAAA',
    selectBackground: '#ffffff',
    selectionGreen: '#6ed59b',
    shadow: '#AAAAAA',
    stackBarColors: [
      'rgb(51, 178, 223)',
      'rgb(84, 110, 122)',
      'rgb(212, 82, 110)',
      'rgb(19, 216, 170)',
      'rgb(76, 0, 153)',
    ],
    tableHeader: '#2A2A2A',
    transparentBlack: 'rgba(20, 20, 20, 0.5)',
    transparentGrey: 'rgba(36, 44, 50, 0.5)',
    transparentLightGrey: 'rgba(210, 210, 210, 0.05)',
    transparentLightGrey2: 'rgba(173, 173, 173, 0.8)',
    white: '#FFFFFF',
  },
  fonts: {
    awesome5: 'Font Awesome 5 Free',
  },
  padding,
};

export default bopsTheme;
