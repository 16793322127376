import { addUserMetadata } from '@Analytics';
import { Claim } from '@Models';
import { decodeToken, getAccessToken } from '../auth0Access';
import { AppDispatch } from '../store';
import { actions } from './authorization.reducer';

const updateTokenData = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.fetchUserAccessRequest());
    try {
      const token = await getAccessToken();
      const tokenPayload = decodeToken(token);

      addUserMetadata({
        clientName: tokenPayload[Claim.Username] ?? '',
        email: tokenPayload[Claim.Email] ?? '',
        userName: tokenPayload[Claim.UserDisplayName] ?? '',
      });

      dispatch(actions.fetchUserAccessSuccess({ tokenPayload }));
    } catch (error: any) {
      dispatch(actions.fetchUserAccessFailure({ error: error?.message }));
    }
  };
};

/* eslint-disable import/prefer-default-export */
export { updateTokenData };
