import { useEffect, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import {
  DashboardFrame,
  ExperienceFrameMetadata,
  SimpleMessageEvent,
  SimpleMessageEventHandler,
} from 'amazon-quicksight-embedding-sdk/dist/types';

import { sendCustomEvent } from '@Analytics';
import { ErrorMessage, QuicksightTopic } from '@Components';
import { Container, DashboardContainer } from './monitor.styles';

interface MonitorProps {
  dashboardUrl: string;
  sheet?: string;
}

const Monitor = ({ dashboardUrl, sheet }: MonitorProps) => {
  const [dashboardError, setDashboardError] = useState(false);

  useEffect(() => {
    const buildDashboard = async () => {
      let embeddedDashboardExperience: DashboardFrame;

      const onMessageHandler: SimpleMessageEventHandler = (
        messageEvent: SimpleMessageEvent,
        experienceMetadata?: ExperienceFrameMetadata,
      ) => {
        switch (messageEvent.eventName) {
          case 'ERROR_OCCURRED': {
            console.log('Error occurred while rendering the experience. Error code:', messageEvent.message.errorCode);
            break;
          }
          case 'PARAMETERS_CHANGED': {
            sendCustomEvent('QUICKSIGHT_PARAMETERS_CHANGED', messageEvent.message.changedParameters);
            break;
          }
          case 'SELECTED_SHEET_CHANGED': {
            sendCustomEvent('QUICKSIGHT_SELECTED_SHEET_CHANGED', messageEvent.message.selectedSheet);
            break;
          }
          case 'MODAL_OPENED': {
            embeddedDashboardExperience
              ?.getSelectedSheetId()
              .then((selectedSheetId) => {
                sendCustomEvent('QUICKSIGHT_MODAL_OPENED', {
                  modalName: messageEvent.message.modalName,
                  selectedSheetId,
                });
              })
              .catch((e) => {
                sendCustomEvent('QUICKSIGHT_MODAL_OPENED', {
                  modalName: messageEvent.message.modalName,
                  selectedSheetId: 'Unable to get',
                });
              });
            break;
          }
          case 'CONTENT_LOADED':
          case 'SIZE_CHANGED':
            // No need to send this events to RUM
            break;

          default:
            break;
        }
      };

      const embeddingContext = await createEmbeddingContext();
      embeddedDashboardExperience = await embeddingContext.embedDashboard(
        {
          url: dashboardUrl,
          container: '#my-qs-frame',
          withIframePlaceholder: true,
          // onChange: (changeEvent, metadata) => { },
        },
        {
          sheetOptions: { initialSheetId: sheet },
          onMessage: onMessageHandler,
        },
      );
    };

    buildDashboard().catch((e) => {
      return setDashboardError(true);
    });
  }, []);

  if (dashboardError) return <ErrorMessage />;

  return (
    <Container>
      <QuicksightTopic />
      <DashboardContainer id="my-qs-frame" />
    </Container>
  );
};

export default Monitor;
