import { bopsApi } from '@Network';
import { AppDispatch } from '../store';
import { actions } from './client.reducer';

const fetchClientConfiguration = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.fetchClientConfigurationRequest());
    try {
      const configuration = await bopsApi.getClientConfiguration();
      dispatch(actions.fetchClientConfigurationSuccess({ configuration }));
    } catch (error: any) {
      dispatch(actions.fetchClientConfigurationFailure({ error: error?.message }));
    }
  };
};

/* eslint-disable import/prefer-default-export */
export { fetchClientConfiguration };
