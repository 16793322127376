import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useLocation } from 'react-router-dom';

import { ErrorMessage, Loading } from '@Components';
import { useFetchData } from '@CustomHooks';
import { bopsApi } from '@Network';
import { screenSize } from '@Themes';
import Monitor from './monitor';

const MonitorContainer = () => {
  const location = useLocation();
  const { sheet } = location.state || {};
  const { collapseSidebar, collapsed } = useProSidebar();

  const { data, isLoading, error } = useFetchData(bopsApi.getQuickSightDashboard);

  useEffect(() => {
    if (collapsed || isLoading) return;

    if (window.innerWidth > screenSize.desktop) return;

    collapseSidebar();
  }, [isLoading]);

  if (isLoading) return <Loading title="Hang on we're gathering your dashboard..." />;

  if (error || !data) return <ErrorMessage />;

  return <Monitor dashboardUrl={String(data)} sheet={sheet} />;
};

export default MonitorContainer;
