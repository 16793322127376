import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledIcon = styled(FontAwesomeIcon).attrs(() => ({
  size: '3x',
  color: 'darkred',
}))`
  display: flex;
  margin: auto;
`;

export const Title = styled.h2`
  margin: 0px 0px 20px 0px;
  text-align: center;
  color: black;
  white-space: pre-line;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const InnerContainer = styled.div`
  margin: auto;
`;
