import { useState } from 'react';
import { connect } from 'react-redux';
import { z } from 'zod';
import { Invokable } from '@buildwithlayer/sdk';

import { Claim, Permission } from '@Models';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import config from '../../config';
import RecommendationAiWidget from './recommendationAiWidget';

interface RecommendationAiWidgetContainerProps {
  showPrompt: boolean;
  showComponent: boolean;
  language?: string | null;
}

const RecommendationAiWidgetContainer = ({
  showPrompt,
  showComponent,
  language,
}: RecommendationAiWidgetContainerProps) => {
  const getRecommendationBySkuIdPrompt = () => {
    let textPrompt =
      config.REACT_APP_OPEN_AI_PROMPT ||
      `a function that takes a product SKU as an argument, and return data object with an inventory recommendation for that product if the product exist`;

    const translatedPrompts = { spanish: ' also the response must be in spanish' };
    if (language && language in translatedPrompts) {
      textPrompt = textPrompt.concat(translatedPrompts[language]);
    }

    textPrompt = textPrompt.concat(': \n\n');

    return textPrompt;
  };

  const [prompt, setPrompt] = useState<string>(getRecommendationBySkuIdPrompt());
  if (!showComponent) return null;

  const getRecommendationBySkuId = async (skuId: string): Promise<string> => {
    const aiRecommendation = await bopsApi.getAiRecommendationByProductCode(skuId);

    const justification = `Provide a concise and data-based two sentence justification for
      the recommended_action: ${
        aiRecommendation.recommendedAction
      }, from the following JSON data provided about the product. Use numbers in the response. Product data: ${JSON.stringify(
      aiRecommendation,
    )}`;

    return aiRecommendation.recommendedAction
      ? justification
      : 'Respond with: Item not found, validate product SKU is correct.';
  };

  const invokables = [
    new Invokable({
      name: 'justifyPolicyActionBySkuId',
      description: prompt,
      func: ({ skuId }: { skuId: string }) => getRecommendationBySkuId(skuId),
      schema: z.object({ skuId: z.string() }),
    }),
  ];

  const generalPrompt =
    // 'The following is a conversation between a human and an AI related to Bops Logistics Platform. The AI is terse and responds concisely with data. If the AI does not know the answer to a question or the question is not related to Logistics, it truthfully says it does not know.'; // add constraint here
    '';

  return (
    <RecommendationAiWidget
      isLoading={false}
      invokables={invokables}
      welcomeMessage="This is the bops Assistant. Ask me about the recommendation of any product!"
      prompt={generalPrompt}
      setPrompt={setPrompt}
      showPrompt={showPrompt}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { authorization, client } = state;
  const { permissions }: { permissions: Permission[] } = authorization;

  const showPrompt = permissions.includes(Permission.DevOpenAiPrompt);
  const showComponent = permissions.includes(Permission.OpenAiMonitor);

  const { configuration } = client;
  const { language } = configuration;

  return { showPrompt, showComponent, language };
};

export default connect(mapStateToProps)(RecommendationAiWidgetContainer);
