import { PropsWithChildren } from 'react';

import { sendCustomEvent } from '@Analytics';
import { StyledPrimaryButton, StyledSecondaryButton } from './button.styles';

export interface ButtonProps {
  onClick: () => void;
  buttonName: string;
  extraLogData?: object;
  primary?: boolean;
  height?: string;
  fontSize?: string;
}

const Button = ({
  onClick,
  buttonName,
  extraLogData,
  children,
  primary = true,
  height = '50px',
  fontSize = '1rem',
}: PropsWithChildren<ButtonProps>) => {
  const internalOnClick = () => {
    sendCustomEvent('BUTTON_CLICKED', { buttonName, ...(extraLogData || {}) });
    onClick();
  };

  return primary ? (
    <StyledPrimaryButton $height={height} $fontSize={fontSize} onClick={internalOnClick}>
      {children}
    </StyledPrimaryButton>
  ) : (
    <StyledSecondaryButton $height={height} $fontSize={fontSize} onClick={internalOnClick}>
      {children}
    </StyledSecondaryButton>
  );
};

export default Button;
